.kcs-overlay{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width:  100vw;
    overflow: hidden;
    z-index: 100;
    background: #FDEDD1;
}

.kcs-loading-container{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    text-align: center;
    width: 100%;
}

.kcs-loading-container p{
    position: relative;
    left: 2rem;
    text-align: center !important;

}