.kcs-project-main{
    display: block;
    min-height: calc(100vh - 80pt);
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.kcs-project-main::before{
    content: "";
    display: block;
    width: 20rem;
    height: calc(100vh - 80pt);
    position: absolute;
    top: 0;
    left: 0;
    background: #ffc00e;
    clip-path: polygon(0% 0%, 90% 50%, 0% 100%);
    opacity: .9;
    z-index: 2;
}

.kcs-project-main::after{
    content: "";
    display: block;
    width: 100%;
    height: calc(100vh - 80pt);
    background: #362d03;
    opacity: .8;
}

.kcs-project-title-div{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20rem;
    z-index: 10;
    color: white;
    text-transform: uppercase;
}



@media (max-width: 700px){
    .kcs-project-main::before{
        display: none;
    }

    .kcs-project-title-div{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2rem;
        z-index: 10;
        color: white;
        text-transform: uppercase;
    }
}

.carousel-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7);
    padding-top: 6rem;
}

.close-button {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 10000;
    font-size: 24px;
    background-color: rgba(0, 0, 0, 0.0);
    color: white;
    border: none;
    font-weight: bold;
    cursor: pointer;
    padding: 1px 10px;
    border-radius: 50%;
  }
  .close-button:hover{
    color: #e54747;
    background: transparent;
  }