.kcs-loader{
    position: relative;
    display: block;
    height: fit-content;
    margin: 3rem auto;
    transform: translateX(50%);

}
.kcs-k{
    display: block;
    height: 5rem;
    width: 1.3rem;
    z-index: -1;
    background: black;
    position: relative;
    left: -8pt;
    top: .3rem;
}
.kcs-loader::after{
    content: "";
    position: absolute;
    right: 0;
    top: 5.4rem;
    width: 40%;
    height: 5rem;
    transform: translate(-50%, -50%);
    background: #ffc00e;
    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 100%, 50% 50%, 0% 0%);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 100%, 50% 50%, 0% 0%);
    animation-name: kcsloadingAnimation;
    animation-duration: .7s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-delay: .7s;
    animation-fill-mode: forwards;
    opacity: .8;
}
.kcs-loader::before{
    content: "";
    position: absolute;
    left: 0;
    top: .3rem;
    width: 40%;
    height: 5rem;
    background: #ffc00e;
    -webkit-clip-path: polygon(100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%, 50% 0%);
    clip-path: polygon(100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%, 50% 0%);
    animation-name: kcsloadingAnimation;
    animation-duration: .7s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-direction: alternate-reverse;
    opacity: .3;
}

@keyframes kcsloadingAnimation {
    0%{
        opacity: 0;
    }
    
    100%{
        opacity: 1;
    }
}